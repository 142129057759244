.MuiInputBase-root {
	background-color: #fff;
}

/* // scroll bar */
::-webkit-scrollbar {
	width: 0.4em;
	height: 0.4em;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
}

::-webkit-scrollbar-thumb {
	background-color: #00509e;
	border-radius: 20px;
	/* // outline: 1px solid slategrey; */
}
